import { Box, Typography } from "@mui/material";
import React from "react";
import IconClockSmall from "../svg-icons/IconClockSmall";
import { useTranslation } from "react-i18next";

const Small = ({ setViewType, days }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        padding: "14px 16px",
        alignItems: "center",
        backgroundColor: "#212529",
        position: "fixed",
        bottom: "18px",
        left: "18px",
        borderRadius: "12px",
        gap: "6px",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        localStorage.setItem("viewType", "large");
        setViewType("large");
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconClockSmall />
      </Box>

      <Box>
        <Typography
          sx={{
            color: "#F8F9FA",
            fontSize: "15px",
            fontStyle: "normal",
            lineHeight: "normal",
          }}
        >
          {t("free_trial_ends")} {days > 0 ? days : 0}{" "}
          {days === 1 ? t("free_trial_ends_day") : t("free_trial_ends_days")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Small;
