import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FolyeoBanner from "src/assets/images/folyeo-banner.png";
import GoogleLogin from "src/views/welcome/login/google";
import LinkedLogin from "src/views/welcome/login/linkedin";

const FooterImage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => navigate(`/register`)}
      >
        <img
          src={FolyeoBanner}
          alt="Banner, Folyeo, Treffas Banner"
          style={{ width: "100%" }}
        />
      </Box>

      <Box
        sx={{
          mt: { xs: "22px", md: "32px" },
          p: { xs: "14px", md: "24px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: "24px",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "470px" } }}>
          <Typography
            sx={{
              color: "#1D1B1B",
              fontSize: "24px",
              lineHeight: "normal",
              fontFamily: "var(--font-alike)",
            }}
          >
            Make the Most of your Booking!
          </Typography>

          <Typography
            sx={{
              mt: "16px",
              color: "#6E757C",
              fontSize: "16px",
              lineHeight: "22px",
            }}
          >
            You’ve booked your appointment—now take the next step! Sign up to
            easily manage your bookings, access exclusive resources, and stay
            connected with expert coaches.
          </Typography>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "195px" },
            display: "flex",
            justifyContent: "flex-left",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <GoogleLogin allowPrompt={true} />
          </Box>

          <Box sx={{ mt: "8px" }}>
            <LinkedLogin />
          </Box>

          <Box
            sx={{
              mt: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#6E757C",
                fontSize: "14px",
              }}
            >
              Have an account?{" "}
              <a href="/login" style={{ color: "var(--color-orange)" }}>
                Login{" "}
              </a>{" "}
              here.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterImage;
