import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
  FormHelperText,
  IconButton,
} from "@mui/material";
import React, { Fragment } from "react";
import { Controller as Fc } from "react-hook-form";
import { useTranslation } from "react-i18next";
import StepperGuide from "src/components/StepperGuide";
import TreffasTextField from "src/components/TreffasTextField";
import TreffasStyle from "src/utils/TreffasStyle";
import Agreement from "../agreement";
import { Info, Upload, XCircle } from "react-feather";
import Attachments from "./addiotional-information/Attachments";
import Helper from "src/utils/Helper";
import VisuallyHiddenInput from "src/components/VisuallyHiddenInput";
import { useFacebookPixelPublic } from "src/views/hooks/useFacebookPixelPublic";

const Information = ({
  errors,
  control,
  selectedOffer,
  clearErrors,
  hideThisSurvey,
  nextButtonRef,
  stepperGuideLength,
  isProcess,
  submitButtonRef,
  setValue,
  extraFieldSelectedFiles,
  setExtraFieldSelectedFiles,
  coachDetails,
}) => {
  const { t } = useTranslation();

  const handleSelectedFiles = (e, field_name) => {
    let selectedFiles = e.target.files;

    if (selectedFiles.length === 0) return;

    delete errors[field_name];

    setExtraFieldSelectedFiles((prev) => {
      let olddata = prev[field_name] || [];

      return {
        ...prev,
        [field_name]: [...olddata, ...selectedFiles],
      };
    });
  };

  const user_settings = coachDetails?.settings
    ? JSON.parse(coachDetails?.settings)
    : null;
  const __analytics = user_settings?.analytics ?? null;
  let enable = __analytics?.has_meta_pixel ?? false;
  let pixelId = __analytics?.meta_pixel ?? null;

  const trackEventInFacebook = useFacebookPixelPublic({
    track: "ViewContent",
    content_name: `${selectedOffer?.name ?? ""} Create booking Form`,
    content_description: `${
      selectedOffer?.description ?? "Package create booking form."
    }`,
    enable: enable,
    pixelId: pixelId,
  });

  const onhandleEvent = () => {
    trackEventInFacebook();
    submitButtonRef.current.click();
  };

  return (
    <Box
      sx={{
        pt: "58px",
      }}
    >
      <Box>
        <Grid container spacing={"20px"}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                mb: "5px",
              }}
            >
              <Box>
                <Typography
                  className="heading-6"
                  sx={{
                    alignItems: "center",
                    color: "#060A2D",
                  }}
                >
                  {t(
                    "individual.booking.schedule.choose_schedule.client_details"
                  )}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#878787",
                  }}
                >
                  {t("individual.booking.schedule.choose_schedule.final_step")}
                </Typography>
              </Box>

              <Box>
                <StepperGuide
                  length={stepperGuideLength}
                  activeLength={[1, 2]}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.firstname")}{" "}
              </Typography>

              <Fc
                name="firstname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TreffasTextField
                    {...field}
                    fullWidth
                    placeholder="Alex"
                    type="text"
                    sx={TreffasStyle.formInputLarge("input")}
                    error={Boolean(errors.firstname)}
                    helperText={errors.firstname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.lastname")}{" "}
              </Typography>

              <Fc
                name="lastname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TreffasTextField
                    {...field}
                    fullWidth
                    placeholder="Smith"
                    type="text"
                    sx={TreffasStyle.formInputLarge("input")}
                    error={Boolean(errors.lastname)}
                    helperText={errors.lastname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {t("booking.label.client_information.phonenumber")}
              </Typography>

              <Fc
                name="mobile"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TreffasTextField
                    {...field}
                    fullWidth
                    placeholder="+63 917000 0000"
                    type="text"
                    sx={TreffasStyle.formInputLarge("input")}
                    error={Boolean(errors.mobile)}
                    helperText={errors.mobile?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography sx={TreffasStyle.formLabel}>
                {" "}
                {t("booking.label.client_information.email")}{" "}
              </Typography>

              <Fc
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TreffasTextField
                    {...field}
                    fullWidth
                    placeholder="alexsmith@gmail.com"
                    type="text"
                    sx={TreffasStyle.formInputLarge("input")}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          {/* additional fields */}
          {(selectedOffer?.fields?.length > 0 ||
            selectedOffer?.custom_options?.package_files?.length > 0) && (
            <Grid item xs={12}>
              <Box mt={"12px"}>
                <Typography
                  className="heading-6"
                  sx={{
                    color: "#060A2D",
                  }}
                >
                  {t("booking.label.additional_information")}
                </Typography>
              </Box>
            </Grid>
          )}

          {selectedOffer?.fields.map((extra, key) => {
            let options = extra?.options ? JSON.parse(extra.options) : null;
            let uploadedFile =
              extraFieldSelectedFiles[Helper.createSlug(extra.name)] ?? [];
            const isUpload = extra.type === "upload" ? true : false;

            return (
              <Fragment key={key}>
                <Grid item xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        ...TreffasStyle.formLabel,
                        textTransform: "capitalize",
                      }}
                    >
                      {extra.name?.toLowerCase()}

                      {options?.has_description && (
                        <Typography
                          variant={"caption"}
                          sx={{ cursor: "help", ml: "10px" }}
                        >
                          <Tooltip arrow title={options?.description}>
                            <Info size={15} color={"#6E757C"} />
                          </Tooltip>
                        </Typography>
                      )}
                    </Typography>

                    {isUpload &&
                      Boolean(errors[Helper.createSlug(extra.name)]) && (
                        <FormHelperText error>
                          {errors[Helper.createSlug(extra.name)]?.message}{" "}
                        </FormHelperText>
                      )}

                    {extra.type === "option" ? (
                      <Fc
                        name={Helper.createSlug(extra.name)}
                        control={control}
                        rules={{
                          required: Boolean(parseInt(extra.required)),
                        }}
                        render={({ field }) => {
                          return (
                            <TreffasTextField
                              {...field}
                              placeholder={`Please select your answer...`}
                              fullWidth
                              type={"text"}
                              variant="outlined"
                              sx={{
                                ...TreffasStyle.formInputLarge("input"),
                                "& .MuiNativeSelect-select": {
                                  textTransform: "capitalize",
                                },
                              }}
                              select
                              SelectProps={{
                                native: true,
                              }}
                              error={Boolean(
                                errors[Helper.createSlug(extra.name)]
                              )}
                              helperText={
                                errors[Helper.createSlug(extra.name)]?.message
                              }
                              value={field.value ?? ""}
                            >
                              <option value={""}> Select </option>
                              {JSON.parse(extra.values).map((option, key) => (
                                <option
                                  key={key}
                                  values={option?.value ?? option}
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {option?.value ?? option}
                                </option>
                              ))}
                            </TreffasTextField>
                          );
                        }}
                      />
                    ) : isUpload ? (
                      <Box>
                        <Box>
                          <Button
                            component="label"
                            variant="contained"
                            startIcon={<Upload size={18} />}
                            fullWidth
                          >
                            Upload file
                            <VisuallyHiddenInput
                              type="file"
                              multiple
                              onChange={(e) =>
                                handleSelectedFiles(
                                  e,
                                  Helper.createSlug(extra.name)
                                )
                              }
                            />
                          </Button>
                        </Box>

                        <Box>
                          {Array.from(uploadedFile).map((file, xfile) => {
                            return (
                              <Box
                                key={xfile}
                                sx={{
                                  m: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  &bull;{" "}
                                  <Typography variant={"caption"}>
                                    {file.name}
                                  </Typography>
                                </Box>

                                <Box sx={{ maxWidth: "20px" }}>
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      let newFile = Array.from(
                                        uploadedFile
                                      )?.filter((x, key) => key !== xfile);

                                      setExtraFieldSelectedFiles((prev) => {
                                        return {
                                          ...prev,
                                          [Helper.createSlug(extra.name)]:
                                            newFile,
                                        };
                                      });
                                    }}
                                  >
                                    <XCircle size={18} />
                                  </IconButton>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    ) : (
                      <Fc
                        name={Helper.createSlug(extra.name)}
                        control={control}
                        rules={{
                          required: Boolean(parseInt(extra.required)),
                        }}
                        render={({ field }) => {
                          return (
                            <TreffasTextField
                              {...field}
                              value={field.value ?? ""}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors(`${Helper.createSlug(extra.name)}`);
                              }}
                              placeholder={`Please write your answer...`}
                              fullWidth
                              type={extra.type === "email" ? "email" : "text"}
                              variant="outlined"
                              sx={TreffasStyle.formInputLarge(
                                extra.type === "paragraph"
                                  ? "textarea"
                                  : "input"
                              )}
                              error={Boolean(
                                errors[Helper.createSlug(extra.name)]
                              )}
                              helperText={
                                errors[Helper.createSlug(extra.name)]?.message
                              }
                              multiline={
                                extra.type === "paragraph" ? true : false
                              }
                              minRows={extra.type === "paragraph" ? 3 : 0}
                            />
                          );
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Fragment>
            );
          })}

          {/* additional attachments */}
          {selectedOffer?.custom_options?.package_files && (
            <Attachments
              package_files={
                JSON.parse(selectedOffer?.custom_options?.package_files) ?? []
              }
            />
          )}
        </Grid>
      </Box>

      <Box sx={{ mt: "32px" }}>
        <Box>
          <Agreement errors={errors} setValue={setValue} />
        </Box>

        <Box>
          <Box
            sx={{
              mt: "30px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            {!hideThisSurvey && selectedOffer?.survey.length > 0 && (
              <Button
                variant={"contained"}
                sx={{
                  height: "48px",
                  borderRadius: "12px",
                  fontSize: "14px",
                  ...TreffasStyle.buttonHover,
                }}
                onClick={() => nextButtonRef.current.click()}
              >
                {t(
                  "individual.booking.schedule.choose_schedule.button.continue_survey"
                )}
              </Button>
            )}

            {((hideThisSurvey && selectedOffer?.survey.length > 0) ||
              selectedOffer?.survey.length === 0) && (
              <Button
                variant={"contained"}
                sx={{
                  height: "48px",
                  borderRadius: "12px",
                  fontSize: "14px",
                  ...TreffasStyle.buttonHover,
                }}
                onClick={() => onhandleEvent()}
                disabled={isProcess}
                startIcon={
                  isProcess && <CircularProgress size={15} color="inherit" />
                }
              >
                {t(
                  "individual.booking.schedule.choose_schedule.button.schedule_event"
                )}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Information;
