const Plans = {
  list: [
    {
      name: "Free",
      value: "free",
      amount: 0,
      inclusive: [
        "Calendar connection",
        "Unlimited number of clients",
        "Unlimited bookings per month",
        "Create 1-on-1 event or package",
        "Single of multiple sessions",
        "Member support via email",
      ],
    },

    {
      name: "Professional",
      // value: "price_1R6qsIIGp19AoPBHmTfEdbFD", // free local
      // value: "price_1NkjRNBMHviY1tXYAnkYXWgX", // 19USD live
      value: "price_1QtQQqBMHviY1tXYBux9TSxf", // 39USD live
      type: "monthly",
      amount: 39,
      inclusive: [
        "Everything in Free Plan + more…",
        "Create group events or packages",
        "Payments with Stripe & Paypal",
        "Connect to email marketing tools",
        "Embed scheduler into your website",
        "Priority Support",
      ],
    },

    {
      name: "Professional",
      // value: "price_1R6qsIIGp19AoPBHmTfEdbFD", // free local
      // value: "price_1NkjSnBMHviY1tXYTpNNW6dd", // 190USD live
      value: "price_1QtQRoBMHviY1tXYf1Sxbhzt", // 390 live
      type: "yearly",
      amount: 390,
      inclusive: [
        "Everything in Free Plan + more…",
        "Create group events or packages",
        "Payments with Stripe & Paypal",
        "Connect to email marketing tools",
        "Embed scheduler into your website",
        "Priority Support",
      ],
    },
  ],
};

export default Plans;
