import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const PowerdBy = ({ colored, withborder = true }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderTop: `${colored ? 0 : !withborder ? 0 : 1}px solid #FAFAFA`,
        width: "100%",
        height: 70,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colored ? "#FAFAFA" : "#FFFFFF",
      }}
    >
      <Box>
        <noscript>
          <a href={window.location.href} target="_blank">
            Please fill out the form
          </a>
        </noscript>
        <div className="treffas-webform-container">
          <Typography
            variant={"caption"}
            sx={{
              fontSize: "14px",
              lineHeight: "31px",
              color: "#838383",
            }}
          >
            {t("powered_by")}{" "}
          </Typography>

          <a
            className="treffas-webform-inner"
            rel="nofollow"
            href="https://folyeo.com"
            target="_blank"
            style={{ color: "var(--color-black)" }}
          >
            Folyeo.
          </a>
        </div>
      </Box>
    </Box>
  );
};

export default PowerdBy;
