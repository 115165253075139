import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import Frame from "src/assets/images/sidebar/frame.png";
import Logo from "src/components/Logo";

const SideBg = () => {
  const themes = useTheme();

  return (
    <Box
      sx={{
        [themes.breakpoints.down("lg")]: {
          display: "none",
        },
        backgroundImage: `url(${Frame})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#cccccc",
        backgroundSize: "cover",
        width: "100%",
        maxWidth: "1100px",
        minHeight: "calc(100vh - 0px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "64px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Logo />
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            pb: "40px",
          }}
        >
          <Typography
            sx={{
              fontSize: "26px",
              letterSpacing: "-0.26px",
              lineHeight: "normal",
              textAlign: "center",
            }}
          >
            Your complete{" "}
            <span
              style={{ color: "#D97855", cursor: "pointer" }}
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_HOME_URL}/features`)
              }
            >
              client and
            </span>{" "}
            <br />
            business{" "}
            <span
              style={{ color: "#D97855", cursor: "pointer" }}
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_HOME_URL}/features`)
              }
            >
              {" "}
              solution{" "}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SideBg;
