/* eslint-disable react/no-array-index-key */
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "src/components/guard/AuthGuard";
import Logout from "src/components/Logout";
import GuardedBaseLayout from "src/layout/menu/guarded";
import Integrations from "./integrations";
import Onboarding from "./onboarding";

const GuardedRoutes = [
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/*",
    component: () => <Navigate to={`/app`} />,
    show: false,
  },

  {
    path: "/app",
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    exact: true,
    // component: lazy(() => import("src/views/coach/calendar")),
    component: lazy(() => import("src/views/coach/calendar-v2")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/calendar",
    // component: lazy(() => import("src/views/coach/calendar")),
    component: lazy(() => import("src/views/coach/calendar-v2")),

    show: false,
    name: "navbar.calendar",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/v2/calendar",
    component: lazy(() => import("src/views/coach/calendar-v2")),
    show: true,
    name: "navbar.calendar",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments",
    component: lazy(() => import("src/views/coach/appointments")),
    show: true,
    name: "navbar.appointments",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients",
    component: lazy(() => import("src/views/coach/clients")),

    show: true,
    name: "navbar.clients",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/audience",
    component: lazy(() => import("src/views/coach/audience")),
    show: true,
    name: "audience",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages",
    component: lazy(() => import("src/views/coach/packages")),
    show: true,
    name: "navbar.packages",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survies",
    component: lazy(() => import("src/views/coach/survies")),
    show: true,
    name: "navbar.label.survey_tool",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/posts",
    component: lazy(() => import("src/views/coach/posts")),
    show: true,
    name: "navbar.label.newsletter_and_posts",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/posts/new",
    component: lazy(() => import("src/views/coach/posts/make/new")),
    show: false,
    name: "new-posts",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/posts/update/:postId",
    component: lazy(() => import("src/views/coach/posts/make/changes")),
    show: false,
    name: "update-posts",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/posts/metrics/:postId",
    component: lazy(() => import("src/views/coach/posts/lists/metrics")),
    show: false,
    name: "metrics-posts",
  },

  {
    show: false,
    name: "navbar.invoices",
    withSubs: true,
    path: "/app/invoices/*",
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "list",
        component: lazy(() => import("src/views/coach/invoices/invoice")),
        name: "navbar.invoices.dropdown.invoice",
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/direct/details/:invoiceId",
        component: lazy(() =>
          import("src/views/coach/invoices/invoice/details")
        ),
        show: false,
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/direct/make/changes/:invoiceId",
        component: lazy(() =>
          import("src/views/coach/invoices/invoice/make/changes")
        ),
        show: false,
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/direct/make/new",
        component: lazy(() =>
          import("src/views/coach/invoices/invoice/make/new")
        ),
        show: false,
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "subscriptions",
        component: lazy(() => import("src/views/coach/invoices/subscriptions")),
        // name: "List of Questions",
        name: "navbar.invoices.dropdown.subscriptions",
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/subscriptions/make/new",
        component: lazy(() =>
          import("src/views/coach/invoices/subscriptions/make/new")
        ),
        show: false,
      },
      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/subscriptions/make/changes/:invoiceId",
        component: lazy(() =>
          import("src/views/coach/invoices/subscriptions/make/changes")
        ),
        show: false,
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "/subscriptions/details/:invoiceId",
        component: lazy(() =>
          import("src/views/coach/invoices/subscriptions/details")
        ),
        show: false,
      },
    ],
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/create",
    component: lazy(() => import("src/views/coach/appointments/shedule/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/details/:appointment_id",
    component: lazy(() => import("src/views/coach/appointments/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/edit/:appointment_id",
    component: lazy(() =>
      import("src/views/coach/appointments/shedule/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/draft/edit/:draft_id",
    component: lazy(() => import("src/views/coach/appointments/drafted/edit")),
    show: false,
  },

  /* new appointment layout */
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/make/new",
    component: lazy(() => import("src/views/coach/appointments/make/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/appointments/make/changes/:appointmentId",
    component: lazy(() => import("src/views/coach/appointments/make/changes")),
    show: false,
  },

  // clients route
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/create",
    component: lazy(() => import("src/views/coach/clients-depreciated/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/details/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/appointments/create/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/appointments/create")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/contract/create/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/contracts/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/contract/edit/:clientId/:contractId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/contracts/edit")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/notes/create/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/notes/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/details/notes/edit/:clientId/:noteId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/notes/edit")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/details/personal/edit/:clientId",
    component: lazy(() =>
      import(
        "src/views/coach/clients-depreciated/details/overview/personal/update"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/details/email-messaging/compose/email/:clientId",
    component: lazy(() =>
      import(
        "src/views/coach/clients-depreciated/details/email-messages/compose"
      )
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients-depreciated/details/invoices/new/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients-depreciated/details/invoices/new")
    ),
    show: false,
  },
  // clients route end

  // audience routes

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/create",
    component: lazy(() => import("src/views/coach/clients/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/details/:clientId",
    component: lazy(() => import("src/views/coach/clients/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/contract/create/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients/details/contracts/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/contract/edit/:clientId/:contractId",
    component: lazy(() =>
      import("src/views/coach/clients/details/contracts/edit")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/notes/create/:clientId",
    component: lazy(() => import("src/views/coach/clients/details/notes/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/details/notes/edit/:clientId/:noteId",
    component: lazy(() => import("src/views/coach/clients/details/notes/edit")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/details/personal/edit/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients/details/overview/personal/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/details/email-messaging/compose/email/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients/details/email-messages/compose")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/clients/details/invoices/new/:clientId",
    component: lazy(() =>
      import("src/views/coach/clients/details/invoices/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/audience/subscriber/new",
    component: lazy(() =>
      import("src/views/coach/audience/subscribers/make/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/audience/subscriber/update/:subscriberId",
    component: lazy(() =>
      import("src/views/coach/audience/subscribers/make/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/audience/details/:subscriberId",
    component: lazy(() =>
      import("src/views/coach/audience/subscribers/details")
    ),
    show: false,
  },
  // audiences routes end

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated",
    component: lazy(() => import("src/views/coach/offers-depreciated")),
    show: false,
    name: "navbar.packages",
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/details/email/:packageId",
    component: lazy(() =>
      import("src/views/coach/packages/details/stepper/emails/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/details/email/update/:packageId/:emailId",
    component: lazy(() =>
      import("src/views/coach/packages/details/stepper/emails/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/details/sms/new/:packageId",
    component: lazy(() =>
      import("src/views/coach/packages/details/stepper/sms/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/details/sms/update/:packageId/:smsId",
    component: lazy(() =>
      import("src/views/coach/packages/details/stepper/sms/update")
    ),
    show: false,
  },

  /** offers routes **/
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/create",
    component: lazy(() => import("src/views/coach/offers-depreciated/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/new",
    component: lazy(() =>
      import("src/views/coach/offers-depreciated/make/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/new",
    component: lazy(() => import("src/views/coach/packages/create/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/update/:packageId",
    component: lazy(() => import("src/views/coach/packages/create/changes")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/new-digital",
    component: lazy(() =>
      import("src/views/coach/packages/create-digital/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/new-course",
    component: lazy(() => import("src/views/coach/packages/create-course/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/digital-product/update/:packageId",
    component: lazy(() =>
      import("src/views/coach/packages/create-digital/changes")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/course/update/:packageId",
    component: lazy(() =>
      import("src/views/coach/packages/create-course/changes")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/details/:packageId",
    component: lazy(() => import("src/views/coach/packages/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/packages/update/landing-page/:packageId",
    component: lazy(() =>
      import("src/views/coach/packages/create/changes/landing-page")
    ),
    show: false,
  },

  // v2-integrations-routes

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/new/v2-integrations",
    component: lazy(() =>
      import("src/views/coach/offers-depreciated/make/new-v2-integrations")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/details/:offerId",
    component: lazy(() => import("src/views/coach/offers-depreciated/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/edit/:offerId",
    component: lazy(() => import("src/views/coach/offers-depreciated/update")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/offers-depreciated/update/:offerId",
    component: lazy(() =>
      import("src/views/coach/offers-depreciated/make/changes")
    ),
    show: false,
  },

  /** offers routes end **/

  /** integrations start **/
  ...Integrations,
  /** integration end **/

  /** onboarding routes start **/
  ...Onboarding,
  /** onboarding routes end **/

  // old survey
  {
    show: false,
    name: "navbar.survey",
    withSubs: true,
    path: "/app/survey/*",
    routes: [
      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "list",
        component: lazy(() => import("src/views/coach/survey")),
        name: "navbar.list-of-survey",
      },

      {
        exact: true,
        guard: AuthGuard,
        layout: GuardedBaseLayout,
        path: "questions",
        component: lazy(() => import("src/views/coach/survey-questions")),
        // name: "List of Questions",
        name: "navbar.list-of-survey-questions",
      },
    ],
  },
  //old survey route end

  // new survies routes -- with ai

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survies/make/new",
    component: lazy(() => import("src/views/coach/survies/make/new")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survies/make/update/:surveyId",
    component: lazy(() => import("src/views/coach/survies/make/changes")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survies/details/:surveyId",
    component: lazy(() => import("src/views/coach/survies/details")),
    show: false,
  },

  // new end

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/create",
    component: lazy(() => import("src/views/coach/survey/create")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/details/:surveyId",
    component: lazy(() => import("src/views/coach/survey/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/generate/link/:surveyId",
    component: lazy(() =>
      import("src/views/coach/survey/generated/SurveyLink")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/edit/:surveyId",
    component: lazy(() => import("src/views/coach/survey/update")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/questions/create",
    component: lazy(() => import("src/views/coach/survey-questions/create")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/questions/details/:questionId",
    component: lazy(() => import("src/views/coach/survey-questions/details")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/questions/edit/:questionId",
    component: lazy(() => import("src/views/coach/survey-questions/update")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/notifications",
    component: lazy(() => import("src/views/coach/notifications")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/profile",
    component: lazy(() => import("src/views/coach/profile")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website",
    component: lazy(() => import("src/views/coach/website")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website/customize",
    component: lazy(() => import("src/views/coach/website/customize")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website/terms-and-condition/new",
    component: lazy(() =>
      import("src/views/coach/website/policies/terms-condition/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website/terms-and-condition/update/:termsId",
    component: lazy(() =>
      import("src/views/coach/website/policies/terms-condition/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website/policy/new",
    component: lazy(() =>
      import("src/views/coach/website/policies/privacy-policy/new")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/website/policy/update/:policyId",
    component: lazy(() =>
      import("src/views/coach/website/policies/privacy-policy/update")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/billings",
    component: lazy(() => import("src/views/coach/billings")),
    show: false,
  },

  // sms redirect of payments
  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/billings/sms-credits/payment/canceled",
    component: lazy(() =>
      import("src/views/coach/billings/sms/redirects/Cancel")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/billings/sms-credits/payment/success",
    component: lazy(() =>
      import("src/views/coach/billings/sms/redirects/Success")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/billings/plans/upgrade",
    component: lazy(() =>
      import("src/views/coach/billings/details/plan-upgrade")
    ),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/settings",
    component: lazy(() => import("src/views/coach/settings")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/settings/account-deletion",
    component: lazy(() => import("src/views/coach/settings/account-deletion")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/logout",
    component: () => <Logout />,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/*",
    component: lazy(() => import("src/components/PageNotFound")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/survey/*",
    component: lazy(() => import("src/components/PageNotFound")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/onboarding",
    component: lazy(() => import("src/views/onboarding")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/v2/onboarding",
    component: lazy(() => import("src/views/onboarding-v2")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/v2/integration",
    component: lazy(() => import("src/views/coach/integrations/v2/index")),
    show: false,
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: GuardedBaseLayout,
    path: "/app/settings/email-tags",
    component: lazy(() => import("src/views/coach/settings/email-tags")),
    show: false,
  },
];

export default GuardedRoutes;
