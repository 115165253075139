import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RM from "src/assets/images/remove.png";
import IsMounted from "src/components/IsMounted";
import { useSnackbarContext } from "src/context/SnackbarContext";
import http from "src/utils/http";

const Verification = ({ email, name }) => {
  const [isProcess, setIsProcess] = useState(true);
  const [isResentProcess, setIsResentProcess] = useState(false);
  const { showSnackbar } = useSnackbarContext();
  const baseURL = window.location.origin;
  const { t } = useTranslation();
  const mounted = IsMounted();

  const [resent, setResent] = useState({
    enable: false,
    token: null,
  });

  const onVerify = React.useCallback(() => {
    http
      .post("/api/oauth/registration/sent-verification-email", {
        email: email,
        name: name,
        base_url: baseURL,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            t("registration.form.validation.verification-sent"),
            "success"
          ); //"Verification link successfully sent to your email.");
          setResent({
            enable: true,
            token: data.token,
          });
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsProcess(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, email, baseURL]);

  const onResent = () => {
    setIsResentProcess(true);
    http
      .post("/api/oauth/registration/sent-verification-email-resent", {
        email: email,
        verification_token: resent.token,
        base_url: baseURL,
        name: name,
      })
      .then(({ data }) => {
        if (data.message === "success") {
          showSnackbar(
            t("registration.form.validation.verification-sent"),
            "success"
          ); //"Verification link successfully sent to your email.");
        }
      })
      .catch((err) => {
        console.log(`error:`, err.message);
      })
      .finally(() => setIsResentProcess(false));
  };

  React.useEffect(() => {
    onVerify();
  }, [onVerify]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          minHeight: "calc(85vh - 100px)",
        }}
      >
        <Box>
          <Box>
            <img
              alt={"treffas-logo"}
              src={RM}
              style={{
                width: "199.08px",
              }}
            />
          </Box>

          <Box>
            <Typography
              className="heading-2"
              sx={{
                fontSize: "32px",
                lineHeight: "46px",
                textAlign: "center",
                letterSpacing: "-0.01em",
                fontFamily: "var(--font-alike)",
              }}
            >
              {t("registration.form.verification.verify-text")}
            </Typography>
          </Box>

          <Box mt={"8px"}>
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "24px",
                textAlign: "center",
                whiteSpace: "pre-line",
                fontFamily: "var(--font-work-sans)",
              }}
            >
              {t("registration.form.verification.description.first", {
                name: name ?? "",
              })}
            </Typography>
          </Box>

          <Box mt={"32px"}>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  textAlign: "center",
                  fontFamily: "var(--font-work-sans)",
                  whiteSpace: "pre-line",
                }}
              >
                {t("registration.form.verification.description.second")}
              </Typography>
            </Box>

            <Box sx={{ mt: "8px" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "inherit",
                  borderRadius: "8px",
                  fontSize: "14px",
                  padding: "10px",
                  lineHeight: "31px",
                  height: "48px",
                  width: "184px",
                  fontFamily: "var(--font-work-sans)",
                  backgroundColor: "var(--bg-black)",
                }}
                onClick={() => onResent()}
                disabled={isResentProcess || isProcess}
                startIcon={
                  isResentProcess && (
                    <CircularProgress color="inherit" size={15} />
                  )
                }
                hidden={!resent.enable}
              >
                {/* Resend Verification */}
                {t("registration.form.button.verify-resent")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box height={30}>
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            fontFamily: "var(--font-work-sans)",
          }}
        >
          {/* Questions? Email us at */}
          {t("registration.need_help")}
          <Typography
            variant="subtitle"
            sx={{
              cursor: "pointer",
              marginLeft: 0.5,
              marginRight: 0.5,
              fontFamily: "var(--font-work-sans)",
              color: "var(--color-orange)",
            }}
            onClick={() => (window.location = "mailto:support@folyeo.com")}
          >
            support@folyeo.com
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

export default Verification;
