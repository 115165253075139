import { useEffect, useCallback } from "react";

export const useFacebookPixelPublic = ({
  track,
  content_name,
  content_description,
  enable,
  pixelId,
  content_type = "package",

  currency = "USD",
  value = 0,
  packageId = null,
}) => {
  useEffect(() => {
    if (enable && !window.fbPixelInitialized) {
      window.fbq("init", pixelId); // Initialize Pixel with the given ID
      window.fbPixelInitialized = true; // Set initialized flag
      console.log("Facebook Pixel initialized with ID:", pixelId);
    }
  }, [enable, pixelId]); // Runs only when enable or pixelId changes

  // Define and return a function for tracking events
  const trackEvent = useCallback(() => {
    if (enable && window.fbPixelInitialized) {
      window.fbq("track", track, {
        content_name,
        content_description,
        ...(track === "Purchase" && {
          currency,
          value,
          content_type,
          contents: [{ package_id: packageId, quantity: 1 }],
        }),

        location: window.location.href,
      });
    }
  }, [
    track,
    content_name,
    content_description,
    enable,
    content_type,
    currency,
    value,
    packageId,
  ]);

  return trackEvent; // Return the tracking function to be used onClick
};
