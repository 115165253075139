import { Box, Typography, ButtonBase } from "@mui/material";
import moment from "moment";
import React from "react";
import TreffasStyle from "src/utils/TreffasStyle";

const timeFormat = (date, userTimeFormat) => {
  return moment(date, userTimeFormat).format(userTimeFormat);
};

const RenderTimeSlots = ({
  selectedSessionTime,
  time,
  handleSelectedTime,
  timeHover,
  setTimeHover,
  userTimeFormat,
  _key,
}) => {
  return (
    <ButtonBase
      sx={(themes) => ({
        width: "100%",
        border: "1px solid #D6D6D6",
        borderRadius: "10px",
        height: "42px",
        backgroundColor: "#FFFFFF",
        ...(selectedSessionTime?.start === time.start && {
          backgroundColor: "var(--base-color-ocean)",
          border: "1px solid var(--base-color-ocean)",
        }),
        ...TreffasStyle.buttonHover,
      })}
      onClick={() => handleSelectedTime(time)}
      // onMouseEnter={() => setTimeHover(_key)}
      // onMouseLeave={() => setTimeHover(null)}
    >
      <Box>
        <Typography
          sx={{
            fontFamily: "var(--font-alike) !important",
            fontSize: "14px",
            lineHeight: "18px",
            textAlign: "center",
            letterSpacing: "0.03em",
            color:
              selectedSessionTime?.start === time.start ? "#fff" : "inherit",
          }}
        >
          {timeFormat(new Date(time.start), userTimeFormat)}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

export default RenderTimeSlots;
