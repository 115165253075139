import React, { useCallback, useEffect, useState } from "react";

import { Box, Container, Typography } from "@mui/material";
import http from "src/utils/http";
import SC from "src/assets/images/survey-thank.png";
import PaypalFialedPayments from "../failed-payments/Paypal";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import IconConfirm from "src/components/svg-icons/IconConfirm";
import FooterImage from "../../footer-image";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { useFacebookPixelPublic } from "src/views/hooks/useFacebookPixelPublic";

const PaypalSuccessPayments = ({ session_slug, direct_confirm }) => {
  const { showSnackbar } = useSnackbarContext();
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const { t } = useTranslation();
  const [coachDetails, setCoachDetails] = useState(null);

  const getBooking = useCallback(() => {
    http
      .get(`/api/general/individual/appointment/details/bysession-slug`, {
        params: {
          sent_confirmation_email: false,
          session_slug,
          base_url: window.location.origin,
          payment_type: "paypal",
        },
      })
      .then(({ data }) => {
        if (data.message === "success") {
          setDetails(data.session_details);
          showSnackbar(t("booking.confirm.alert.successful"), "success");

          let tracking_exists =
            localStorage.getItem(`purchased_tracker_${session_slug}`) ?? "";

          if (tracking_exists !== "exists") {
            // get coach details for pixel tracking
            http
              .get("/api/general/individual/coach/coach-details", {
                params: {
                  user_id: data.session_details.user_id,
                  organization_id: data.session_details.organization_id,
                },
              })
              .then(({ data }) => {
                setCoachDetails(data);
                localStorage.setItem(
                  `purchased_tracker_${session_slug}`,
                  "exists"
                );
              })
              .catch((err) => console.log(err.message));
          }
        }
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_slug]);

  useEffect(() => {
    // add time to let paypal process the IPN first
    setTimeout(() => {
      getBooking();
    }, 5000);
  }, [getBooking]);

  const user_settings = coachDetails?.settings
    ? JSON.parse(coachDetails?.settings)
    : null;
  const __analytics = user_settings?.analytics ?? null;
  let enable = __analytics?.has_meta_pixel ?? false;
  let pixelId = __analytics?.meta_pixel ?? null;
  const selectedOffer =
    details?.session_records_firstentry?.appointment?.offer ?? null;

  const trackEventInFacebook = useFacebookPixelPublic({
    track: "Purchase",
    content_name: `${selectedOffer?.name ?? ""} Purchase completed`,
    content_description: `${
      selectedOffer?.description ?? "Purchase completed."
    }`,
    enable: enable,
    pixelId: pixelId,

    // purchased extra config
    currency: selectedOffer?.custom_options?.currency ?? "USD",
    value: selectedOffer?.price ?? 0,
    content_type: selectedOffer?.category ?? "package",
    packageId: selectedOffer?.id,
  });

  React.useEffect(() => {
    if (details?.paypal_ispaid && coachDetails) {
      trackEventInFacebook();
    }
  }, [details, coachDetails]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!Boolean(parseInt(details?.paypal_ispaid))) {
    return <PaypalFialedPayments session_slug={session_slug} />;
  }

  const directConfirm = parseInt(direct_confirm) === 1 ? true : false;

  return (
    <Container maxWidth={"tp"}>
      <Box
        sx={{
          border: { md: "1px solid #D9DBE9" },
          borderRadius: "8px",
          p: { xs: "10px", md: "86px" },
          mt: "80px",
          textAlign: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Box>
            {directConfirm ? (
              <IconConfirm />
            ) : (
              <img
                src={SC}
                alt={"Treffas Logo"}
                style={{
                  width: "120px",
                  height: "120px",
                }}
              />
            )}
          </Box>

          <Box my={"20px"}>
            <Typography
              sx={{
                fontSize: { xs: "19px", md: "22px" },
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {directConfirm
                ? t("booking.confirm.label.appointment_confirm")
                : t("booking.confirm.label.appointment_sent")}
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "31px",
                color: "#060A2D",
              }}
            >
              {directConfirm
                ? t("booking.confirm.label.appointment_confirm.description")
                : t("booking.alert.appointment_sent_description")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "50px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FooterImage />
      </Box>
    </Container>
  );
};

export default PaypalSuccessPayments;
