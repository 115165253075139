import {
  Typography,
  Box,
  Grid,
  TextField,
  useTheme,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import TreffasStyle from "src/utils/TreffasStyle";
import { Controller as Fc } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Square } from "react-feather";
import { grey, red } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import {
  CheckBox as CheckBoxIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const BasicInfo = ({
  control,
  errors,
  trigger,
  terms,
  setTerms,
  submitBtnRef,
  isProcess,
}) => {
  const themes = useTheme();
  const navigate = useNavigate();
  const [termError, setTermError] = useState(false);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleSignup = async () => {
    let error = [];
    const noError = await trigger(["firstname", "lastname", "password"]);

    if (!noError) {
      error = "fields-required";
    }

    if (!terms) {
      error = "terms-required";
      setTermError(true);
    }

    if (error.length === 0) {
      submitBtnRef?.current.click();
    }
  };

  return (
    <>
      <Box>
        <Grid container spacing={"16px"}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                sx={{
                  ...TreffasStyle.formLabel,
                  color: "#060A2D",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.firstname")}
              </Typography>

              <Fc
                name="firstname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Enter first name"
                    type="text"
                    sx={TreffasStyle.formInput(themes)}
                    error={Boolean(errors.firstname)}
                    helperText={errors.firstname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                sx={{
                  ...TreffasStyle.formLabel,
                  color: "#060A2D",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.lastname")}
              </Typography>

              <Fc
                name="lastname"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Enter last name"
                    type="text"
                    sx={TreffasStyle.formInput(themes)}
                    error={Boolean(errors.lastname)}
                    helperText={errors.lastname?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box>
              <Typography
                sx={{
                  ...TreffasStyle.formLabel,
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.email")}
              </Typography>

              <Fc
                name="email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="e.g. bettycooper@gmail.com"
                    type="email"
                    sx={TreffasStyle.formInput(themes)}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box>
              <Typography
                sx={{
                  ...TreffasStyle.formLabel,
                  color: "#060A2D",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.password")}
              </Typography>

              <Fc
                name="password"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    placeholder="Enter password"
                    type={showPassword ? "text" : "password"}
                    sx={TreffasStyle.formInput(themes)}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!showPassword ? (
                              <Visibility sx={{ fontSize: "18px" }} />
                            ) : (
                              <VisibilityOff sx={{ fontSize: "18px" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: "24px" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={terms}
              onChange={() => setTerms(!terms)}
              icon={<Square color={termError ? red[600] : grey[400]} />}
              checkedIcon={<CheckBoxIcon sx={{ fill: "#D97855" }} />}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: "12px",
                textAlign: "left",
                color: "#000",
                fontFamily: "var(--font-work-sans)",
              }}
            >
              {t("registration.form.labels.agreement.first")}
              <Typography
                variant="subtitle"
                sx={{
                  cursor: "pointer",
                  marginLeft: 0.5,
                  marginRight: 0.5,
                  color: "#D97855",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.agreement.second")}
              </Typography>
              {t("registration.form.labels.agreement.third")}
              <Typography
                variant="subtitle"
                sx={{
                  cursor: "pointer",
                  marginLeft: 0.5,
                  marginRight: 0.5,
                  color: "#D97855",
                  fontFamily: "var(--font-work-sans)",
                }}
              >
                {t("registration.form.labels.agreement.fourth")}
              </Typography>
              .
            </Typography>
          }
        />
      </Box>

      <Box sx={{ mt: "24px" }}>
        <Button
          variant="contained"
          fullWidth
          sx={{
            textTransform: "inherit",
            borderRadius: "8px",
            fontSize: "14px",
            padding: "10px",
            lineHeight: "31px",
            height: "48px",
            backgroundColor: "#000",
            fontFamily: "var(--font-work-sans)",
          }}
          onClick={() => handleSignup()}
          disabled={isProcess}
          startIcon={
            isProcess && <CircularProgress color="inherit" size={15} />
          }
        >
          {t("registration.form.button.signup")}
        </Button>
      </Box>

      <Box
        sx={(themes) => ({
          mt: "24px",
          pb: "24px",
        })}
      >
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            color: "#000",
            fontFamily: "var(--font-work-sans)",
          }}
        >
          {t("registration.form.button.no-account.first")}
          <Typography
            variant="subtitle"
            sx={{
              cursor: "pointer",
              marginLeft: 0.5,
              marginRight: 0.5,
              color: "#D97855",
              fontFamily: "var(--font-work-sans)",
            }}
            onClick={() => navigate("/login")}
          >
            {t("registration.form.button.no-account.second")}
          </Typography>
          {t("registration.form.button.no-account.third")}
        </Typography>
      </Box>
    </>
  );
};

export default BasicInfo;
