import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import StepperGuide from "src/components/StepperGuide";
import Booking from "src/utils/Booking";
import RenderTimeSlots from "../RenderTimeSlots";

const DateAndTimezone = ({
  errors,
  selectedTimezone,
  sessionList,
  selectedSessionTime,
  setSessionSelectedTime,
  selectedOffer,
  appForThisOffer,
  blockTimeOnSelectedDate,
  selectedDate,
  setError,
  userDateFormat,
  userTimeFormat,
  userMinimumBookingNotice,
  allUpcommingAppt,
  preparingSessionList,
  stepperGuideLength,
  overFlowTimeToRender,
  underFlowTimeToRender,
  nextButtonRef,
  coachDetails,
}) => {
  const { t } = useTranslation();
  const [timeHover, setTimeHover] = React.useState(null);

  const handleSelectedTime = async (time) => {
    return Booking._dateandtime.handleSelectedTime(
      time,
      userMinimumBookingNotice,
      userDateFormat,
      userTimeFormat,
      setSessionSelectedTime,
      nextButtonRef,
      setError,
      t
    );
  };

  const filterList = (list) => {
    const coachTimezone = coachDetails?.timezone_utc;
    return Booking._dateandtime.filterList(
      list,
      blockTimeOnSelectedDate,
      appForThisOffer,
      allUpcommingAppt,
      selectedOffer,
      selectedTimezone,
      selectedDate,
      coachTimezone
    );
  };

  const ___filteredList = [
    ...overFlowTimeToRender,
    ...sessionList,
    ...underFlowTimeToRender,
  ];

  const filteredList = filterList(___filteredList);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "32px",
            pt: "42px",
            flexDirection: "column",
          }}
        >
          <Box>
            <StepperGuide length={stepperGuideLength} activeLength={[1, 2]} />
          </Box>

          <Box>
            <Typography
              className="heading-6"
              sx={{
                alignItems: "center",
                color: "#060A2D",
              }}
            >
              {t("individual.booking.schedule.choose_schedule")}
            </Typography>

            <Typography
              sx={{ 
                fontSize: "14px",
                lineHeight: "18px",
                color: "#878787",
                mt: "4px",
              }}
            >
              {t("individual.booking.schedule.choose_schedule.description", {
                package: selectedOffer?.name,
              })}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "31px",
        }}
      >
        <Box>
          {/* availabitlity */}
          <Box
            style={{
              width: "100%",
            }}
          >
            <Box>
              <Box mb={"10px"}>
                <Typography
                  sx={{
                    fontFamily: "var(--font-alike) !important",
                    fontSize: "10px",
                    lineHeight: "11px",
                    letterSpacing: "0.07em",
                    color: "#A1A1A1",
                    textTransform: "uppercase",
                  }}
                >
                  {t("booking.placeholder.time_slots")}
                </Typography>

                {Boolean(errors?.time) && (
                  <FormHelperText error>
                    {" "}
                    {errors?.time?.message}{" "}
                  </FormHelperText>
                )}
              </Box>

              {selectedDate === null ? (
                <NoAvailableTime
                  message={t("booking.error.required.select_date")}
                />
              ) : (
                <Box className="session-time-scroll">
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {preparingSessionList ? (
                      <Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          mt: "10px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    ) : filteredList?.length > 0 ? (
                      <Grid container spacing={"10px"}>
                        {filteredList?.map((time, key) => {
                          return (
                            <Grid item xs={6} sm={6} key={key}>
                              <Box key={key}>
                                <RenderTimeSlots
                                  selectedSessionTime={selectedSessionTime}
                                  time={time}
                                  handleSelectedTime={handleSelectedTime}
                                  timeHover={timeHover}
                                  setTimeHover={setTimeHover}
                                  userTimeFormat={userTimeFormat}
                                  _key={key}
                                />
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <NoAvailableTime
                        message={t(
                          "booking.error.required.no_time_on_selected_date"
                        )}
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DateAndTimezone;

const NoAvailableTime = ({ message }) => (
  <Box
    sx={{
      height: "338px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2",
      width: "100%",
      borderRadius: "8px",
    }}
  >
    <Typography
      sx={{
        fontFamily: "var(--font-alike) !important",
        fontSize: "10px",
        lineHeight: "11px",
        letterSpacing: "0.07em",
        color: "#A1A1A1",
        textTransform: "uppercase",
      }}
    >
      {message}
    </Typography>
  </Box>
);
