import {
  Avatar,
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Users } from "src/context";
import Constant from "src/utils/Constant";
import Helper from "src/utils/Helper";
import ShareDialog from "src/views/coach/packages/share/ShareDialog";

const coachCustomPage = process.env.REACT_APP_BLOG_URL;

const DropdownMenu = ({
  anchorElUser,
  handleCloseUserMenu,
  currentPlan,
  setAnchorElUser,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(Users);
  const navigate = useNavigate();
  const [share, setShare] = useState({
    dialog: false,
    type: "booking-url",
  });

  const user_settings = user?.settings ? JSON.parse(user?.settings) : null;
  const website = user_settings?.website ?? null;

  const is_verified = website?.is_verified ?? false;
  const verified_domain = website?.verified_domain ?? null;

  const isInPage = (key) => {
    if (window.location.href.includes(key)) {
      navigate(key);
      navigate(0);
    } else {
      navigate(key);
    }
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      elevation={0}
      PaperProps={{
        sx: {
          width: "284px",
          boxShadow: "0px 13px 30px rgba(0, 0, 0, 0.05)",
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid #EAECEF",
          mt: "8px",
        },
      }}
    >
      {/* menu header */}
      <ItemHeader currentPlan={currentPlan} />
      <Item
        name={t("posts.label.edit_profile")}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/profile");
        }}
      />
      <Item
        name={"Edit Website"}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/website");
        }}
      />
      <Item
        name={t("posts.label.account_settings")}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/settings");
        }}
      />
      <Item
        name={t("posts.label.upgrade_subscription")}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/billings/plans/upgrade");
        }}
      />
      <Item
        name={t("posts.label.billing")}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/billings");
        }}
      />

      <Divider sx={{ borderColor: "#EAECEF" }} />

      <Item
        name={"Manage Tags"}
        onClick={() => {
          isInPage("/app/settings/email-tags");
        }}
      />

      <Item
        name={t("posts.label.booking_url")}
        onClick={() => {
          setShare({ dialog: true, type: "booking-url" });
        }}
      />
      <Item
        name={t("posts.label.website_url")}
        onClick={() => {
          setShare({ dialog: true, type: "website-url" });
        }}
      />
      <Item
        name={t("posts.label.calendar_connection")}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/settings?tab=calendar");
        }}
      />
      <Item
        name={t("integration.page")}
        onClick={() => {
          setAnchorElUser(null);
          isInPage("/app/integrations");
        }}
      />
      <Divider sx={{ borderColor: "#EAECEF" }} />
      {/* <Item
        name={"Delete Account"}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/settings?tab=calendar");
        }}
        color={"#EE6D6D"}
      /> */}
      <Item
        name={t("posts.label.logout")}
        onClick={() => {
          setAnchorElUser(null);
          navigate("/app/logout");
        }}
      />
      {/* share your link dialog */}
      <ShareDialog
        open={share.dialog}
        close={() =>
          setShare({
            dialog: false,
            type: "booking-url",
          })
        }
        bookingURL={
          share.type === "booking-url"
            ? `${window.location.origin}/a/${user?.onboarding?.personal_link}`
            : is_verified
            ? Helper.formatDomain(verified_domain)
            : `${coachCustomPage}/page/${user?.onboarding?.personal_link}`
        }
        type={share.type === "booking-url" ? "booking" : "website"}
      />
    </Menu>
  );
};

export default DropdownMenu;

const ItemHeader = ({ currentPlan }) => {
  const { user } = useContext(Users);

  const user_settings = user?.settings ? JSON.parse(user?.settings) : null;
  const website = user_settings?.website ?? null;

  const is_verified = website?.is_verified ?? false;
  const verified_domain = website?.verified_domain ?? null;

  return (
    <MenuItem
      sx={{
        padding: "14px 20px",
        backgroundColor: "var(--base-color-ocean-100)",
        borderRadius: "12px",
        mb: "12px",
      }}
      onClick={() => {
        const url = is_verified
          ? Helper.formatDomain(verified_domain) // Format the verified domain
          : `${coachCustomPage}/page/${user?.onboarding?.personal_link}`;

        window.open(url, "_blank");
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box>
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: user?.avatar ? "#FFF" : "var(--base-color-warm)",
            }}
            alt={"Treffas AB Image"}
            // src={Constant.imageUrl("coach", user?.avatar)}
            src={Constant.getFileInStorage("coach", user?.avatar)}
          >
            {user?.name?.charAt().toUpperCase()}
          </Avatar>
        </Box>

        <Box sx={{ maxWidth: "130px" }}>
          <Typography
            sx={{
              fontSize: "15px",
              lineHeight: "normal",
              color: "#353A3F",
              textTransform: "capitalize",

              overflow: "hidden", // Prevents content from overflowing
              textOverflow: "ellipsis", // Adds ellipsis for overflowing text
              whiteSpace: "nowrap", // Prevents text wrapping
            }}
          >
            {user?.name}
          </Typography>

          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "16px",
              color: "#6E757C",
            }}
          >
            {currentPlan}
          </Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

const Item = ({ name, onClick, color = "" }) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        padding: "8px 6px 8px 12px",
        height: "36px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "6px",
        }}
      >
        <Box
          sx={{
            fontSize: "15px",
            lineHeight: "normal",
            letterSpacing: "0.3px",
            color: color ? color : "#353A3F",
          }}
        >
          {name}
        </Box>

        <Box>
          <ChevronRight size={14} color={color ? color : "#6E757C"} />
        </Box>
      </Box>
    </MenuItem>
  );
};
