import {
  Box,
  Button,
  Divider,
  CircularProgress,
  MobileStepper,
  Container,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TreffasStyle from "src/utils/TreffasStyle";
import DateAndTime from "./DateAndTime";
import DateAndTimezone from "./DateAndTimezone";
import Information from "./Information";
import Success from "./Success";
import Survey from "./Survey";

const RenderBooking = ({
  handleSubmit,
  onSubmit,
  randomState,
  setRandomState,
  randomErrors,
  setRandomErrors,
  setValue,
  getValues,
  maxSteps,
  handleBack,
  validateOnNext,
  isProcess,
  packagePrice,
  submitButtonRef,
  errors,
  control,
  setSelectedTimezone,
  selectedTimezone,
  sessionList,
  selectedSessionTime,
  setSessionSelectedTime,
  availableTime,
  setSessionList,
  selectedOffer,
  setBlockTimeOnSelectedDate,
  appForThisOffer,
  blockTimeOnSelectedDate,
  selectedDate,
  setSelectedDate,
  setError,
  clearErrors,
  coachOrgSettings,
  coachOrg,
  activeStep,
  coachDetails,
  nextButtonRef,
  calendarMaxDate,
  userDateFormat,
  userTimeFormat,
  userMinimumBookingNotice,
  hideThisSurvey,
  isBookingPaid,

  allUpcommingAppt,
  setAllUpcommingAppt,
  preparingSessionList,
  setPreparingSesisonList,
  setActiveStep,

  overFlowTimeToRender,
  setOverFlowTimeToRender,
  underFlowTimeToRender,
  setUnderFlowTimeToRender,
  isUseTimeRecord,
  setIsUseTimeRecord,
  timeRecords,
  setTimeRecords,
  extraFieldSelectedFiles,
  setExtraFieldSelectedFiles,
}) => {
  const { t } = useTranslation();

  const themes = useTheme();

  const stepperGuideLength = [1, 2, ...(!hideThisSurvey ? [3] : [])];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Container
          maxWidth={activeStep === 0 ? "bp" : "tp"}
          sx={{ p: "0px !important" }}
        >
          <Box
            sx={{
              minHeight:
                activeStep === 0 || activeStep === 4
                  ? "100%"
                  : "calc(90vh - 200px)",
            }}
          >
            {activeStep === 0 && (
              <DateAndTime
                errors={errors}
                control={control}
                setSelectedTimezone={setSelectedTimezone}
                selectedTimezone={selectedTimezone}
                sessionList={sessionList}
                selectedSessionTime={selectedSessionTime}
                setSessionSelectedTime={setSessionSelectedTime}
                availableTime={availableTime}
                setSessionList={setSessionList}
                selectedOffer={selectedOffer}
                setBlockTimeOnSelectedDate={setBlockTimeOnSelectedDate}
                appForThisOffer={appForThisOffer}
                blockTimeOnSelectedDate={blockTimeOnSelectedDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setError={setError}
                clearErrors={clearErrors}
                coachOrgSettings={coachOrgSettings}
                coachOrg={coachOrg}
                activeStep={activeStep}
                nextButtonRef={nextButtonRef}
                calendarMaxDate={calendarMaxDate}
                userDateFormat={userDateFormat}
                userTimeFormat={userTimeFormat}
                userMinimumBookingNotice={userMinimumBookingNotice}
                stepperGuideLength={stepperGuideLength}
                preparingSessionList={preparingSessionList}
                setPreparingSesisonList={setPreparingSesisonList}
                setAllUpcommingAppt={setAllUpcommingAppt}
                setActiveStep={setActiveStep}
                setOverFlowTimeToRender={setOverFlowTimeToRender}
                setUnderFlowTimeToRender={setUnderFlowTimeToRender}
                coachDetails={coachDetails}
                isUseTimeRecord={isUseTimeRecord}
                setIsUseTimeRecord={setIsUseTimeRecord}
                timeRecords={timeRecords}
                setTimeRecords={setTimeRecords}
              />
            )}

            {activeStep === 1 && (
              <DateAndTimezone
                errors={errors}
                control={control}
                selectedTimezone={selectedTimezone}
                sessionList={sessionList}
                selectedSessionTime={selectedSessionTime}
                setSessionSelectedTime={setSessionSelectedTime}
                selectedOffer={selectedOffer}
                setBlockTimeOnSelectedDate={setBlockTimeOnSelectedDate}
                appForThisOffer={appForThisOffer}
                blockTimeOnSelectedDate={blockTimeOnSelectedDate}
                selectedDate={selectedDate}
                setError={setError}
                userDateFormat={userDateFormat}
                userTimeFormat={userTimeFormat}
                userMinimumBookingNotice={userMinimumBookingNotice}
                allUpcommingAppt={allUpcommingAppt}
                setAllUpcommingAppt={setAllUpcommingAppt}
                preparingSessionList={preparingSessionList}
                stepperGuideLength={stepperGuideLength}
                overFlowTimeToRender={overFlowTimeToRender}
                underFlowTimeToRender={underFlowTimeToRender}
                nextButtonRef={nextButtonRef}
                coachDetails={coachDetails}
              />
            )}
            {activeStep === 2 && (
              <Information
                errors={errors}
                control={control}
                selectedOffer={selectedOffer}
                clearErrors={clearErrors}
                hideThisSurvey={hideThisSurvey}
                stepperGuideLength={stepperGuideLength}
                nextButtonRef={nextButtonRef}
                isProcess={isProcess}
                submitButtonRef={submitButtonRef}
                setValue={setValue}
                extraFieldSelectedFiles={extraFieldSelectedFiles}
                coachDetails={coachDetails}
                setExtraFieldSelectedFiles={setExtraFieldSelectedFiles}
              />
            )}

            {activeStep === 3 && (
              <Survey
                hideThisSurvey={hideThisSurvey}
                selectedOffer={selectedOffer}
                errors={errors}
                control={control}
                randomState={randomState}
                setRandomState={setRandomState}
                randomErrors={randomErrors}
                setRandomErrors={setRandomErrors}
                setValue={setValue}
                getValues={getValues}
                nextButtonRef={nextButtonRef}
                stepperGuideLength={stepperGuideLength}
                isProcess={isProcess}
                submitButtonRef={submitButtonRef}
                setError={setError}
              />
            )}
          </Box>

          {activeStep === 4 && (
            <Success
              getValues={getValues}
              selectedOffer={selectedOffer}
              selectedSessionTime={selectedSessionTime}
              coachDetails={coachDetails}
            />
          )}

          {activeStep !== 4 && (
            <Box
              sx={{
                mt: activeStep === 0 ? "50px" : "0px",
                display: "none",
              }}
            >
              {activeStep !== 0 && (
                <Box
                  sx={{
                    my: "24px",
                  }}
                >
                  <Divider />
                </Box>
              )}

              <Box
                sx={{
                  my: "24px",
                }}
              >
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  backButton={
                    <Button
                      variant={"outlined"}
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleBack();
                      }}
                      sx={{
                        [themes.breakpoints.up("md")]: {
                          borderRadius: "12px",
                          fontSize: "14px",
                          minWidth: "100px",
                        },
                        height: "48px",
                        ...TreffasStyle.buttonHover,
                        opacity: activeStep === 0 ? 0 : 1,
                      }}
                    >
                      {t("booking.button.back")}
                    </Button>
                  }
                  nextButton={
                    <>
                      {activeStep !== maxSteps - 1 && (
                        <Button
                          variant={"contained"}
                          onClick={validateOnNext}
                          sx={{
                            display: activeStep === 0 ? "none" : "inline-flex",
                            [themes.breakpoints.up("md")]: {
                              borderRadius: "12px",
                              fontSize: "14px",
                              minWidth: "100px",
                            },
                            height: "48px",
                            ...TreffasStyle.buttonHover,
                          }}
                          type={"button"}
                          ref={nextButtonRef}
                          disabled={isProcess}
                          startIcon={
                            isProcess && (
                              <CircularProgress size={15} color="inherit" />
                            )
                          }
                        >
                          {activeStep === 1
                            ? parseInt(packagePrice) < 1 || isBookingPaid
                              ? t(
                                  "individual.booking.schedule.choose_schedule.button.schedule_event"
                                )
                              : t("booking.button.next")
                            : t("booking.button.next")}
                        </Button>
                      )}

                      {activeStep === maxSteps - 1 && (
                        <Button
                          variant={"contained"}
                          sx={{
                            [themes.breakpoints.up("md")]: {
                              borderRadius: "12px",
                              minWidth: "100px",
                              fontSize: "14px",
                            },
                            height: "48px",
                            ...TreffasStyle.buttonHover,
                          }}
                          type={"submit"}
                          disabled={isProcess}
                          startIcon={
                            isProcess && (
                              <CircularProgress size={15} color="inherit" />
                            )
                          }
                        >
                          {t(
                            "individual.booking.schedule.choose_schedule.button.schedule_event"
                          )}
                        </Button>
                      )}
                    </>
                  }
                  sx={{
                    "& .MuiMobileStepper-dots": {
                      display: "none",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </Container>
      </Box>

      <input type="submit" style={{ display: "none" }} ref={submitButtonRef} />
    </form>
  );
};

export default RenderBooking;
