import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import ShowMoreText from "react-show-more-text";
import { Lock } from "react-feather";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import TreffasStyle from "src/utils/TreffasStyle";
import { Attachment } from "@mui/icons-material";
import Helper from "src/utils/Helper";
import Constant from "src/utils/Constant";

const DigitalPackage = ({
  offerList,
  coachDetails,
  display,
  header,
  footer,
}) => {
  const planSubscription = coachDetails?.inclusive;
  const navigate = useNavigate();
  const isGroupApptsInPlan =
    planSubscription.package.session_type.group ?? false;
  const isMultipleSessionInPlan =
    planSubscription.package.availability.multiple_session ?? false;
  const isPaymentInPlan = planSubscription.package.payments ?? false;

  if (offerList.length === 0) return null;

  return (
    <Box>
      <Grid container spacing={"12px"}>
        {offerList.map((data, key) => {
          const offerType = data?.custom_options?.sessions?.configuration?.type;
          const isOfferTypeGroup = offerType === "group";
          const isMultipleSession =
            offerType === "individual" &&
            parseInt(data?.custom_options?.number_of_sessions) > 1;

          let isPackageLocked =
            (!isGroupApptsInPlan && isOfferTypeGroup) ||
            (!isMultipleSessionInPlan && isMultipleSession) ||
            (data.price > 0 && !isPaymentInPlan);

          let pakage_files = data?.custom_options?.package_files
            ? JSON.parse(data?.custom_options?.package_files)
            : [];

          let attachmentCounts = pakage_files?.flat()?.length ?? 0;

          let option = data?.custom_options;
          const logo = option?.current_logo || null;
          const currentLogo = logo
            ? Constant.getFileInStorage("packages/logo", logo)
            : Helper.getRandomThumbnail();

          return (
            <Grid item key={key} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  gap: { xs: "0px", sm: "32px" },
                  borderBottom:
                    offerList.length === key + 1 ? "0px" : `1px dashed #DBDBDB`,
                  py: "32px",
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${currentLogo})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",

                    minWidth: "152px",
                    minHeight: "152px",

                    width: "152px",
                    height: "152px",
                    borderRadius: "8px",
                  }}
                >
                  {/* image */}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    borderRight: { sm: "1px dashed #DFE2E6" },
                    borderBottom: {
                      xs: "1px dashed #DFE2E6",
                      sm: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      p: { xs: "26px 24px 24px", sm: "24px 32px" },
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        mb: "12px",
                        width: { xs: "100%", md: "688px" },
                      }}
                    >
                      <Typography
                        className="heading-4"
                        sx={{
                          letterSpacing: "0.44px",
                        }}
                      >
                        {data?.name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: {
                          xs: "flex-start",
                          sm: "center",
                        },
                        gap: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "3px 8px",
                          gap: "4px",
                          border: "1px solid #DBDBDB",
                          borderRadius: "13px",
                          minWidth: "71px",
                          height: "22px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Attachment
                            style={{ fontSize: "16px", color: "#A0A6AB" }}
                          />
                          <Typography
                            sx={{
                              fontSize: "10px",
                              lineHeight: "14px",
                              color: "#060A2D",
                              ...(data.status === "inactive" && {
                                color: "#838383 !important",
                              }),
                            }}
                          >
                            {attachmentCounts} Attachments
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        mt: "24px",
                        width: { xs: "100%", md: "688px" },
                      }}
                    >
                      <ShowMoreText
                        lines={3}
                        more={
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "var(--color-orange)",
                            }}
                          >
                            {" Read more"}
                          </Typography>
                        }
                        less={
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "14px",
                              lineHeight: "18px",
                              color: "var(--color-orange)",
                            }}
                          >
                            {" Read less"}
                          </Typography>
                        }
                        className="readmore-content-coach-offerlist-2"
                        expanded={false}
                        truncatedEndingComponent={"..."}
                      >
                        <Typography
                          className="no-margin body-md"
                          // sx={{
                          //   fontSize: "14px",
                          //   lineHeight: "18px",
                          //   color: "#6E757C",
                          // }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.description),
                          }}
                        />
                      </ShowMoreText>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "100%", sm: "200px" },
                    p: { xs: "10px 24px", sm: "32px" },
                    display: "flex",
                    justifyContent: {
                      xs: "space-between",
                      sm: "center",
                    },
                    textAlign: { xs: "left", sm: "center" },
                    alignItems: { xs: "flex-start", sm: "center" },
                    flexDirection: { xs: "row", sm: "column" },
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "169px",
                      mb: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#A0A6AB",
                        fontSize: "12px",
                      }}
                    >
                      Get to know more details about this package.
                    </Typography>
                  </Box>

                  <Box>
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: { xs: "100%", sm: "136px" },
                        minHeight: "44px",
                        borderRadius: "8px",
                        backgroundColor: "var(--bg-black)",
                        color: "#FFF",
                        lineHeight: "normal",
                      }}
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          display,
                          header,
                          footer,
                        });
                        const queryString = queryParams.toString();
                        const url =
                          `/appointment/individual/landing-page/${data?.organization_id}/${data?.user_id}/${data?.id}` +
                          (footer ? `?${queryString}` : "");
                        navigate(url);
                      }}
                      disabled={isPackageLocked}
                      startIcon={isPackageLocked && <Lock size={15} />}
                    >
                      Learn More
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DigitalPackage;
