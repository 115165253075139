import { Dialog, DialogActions, DialogContent, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TreffasStyle from "src/utils/TreffasStyle";

const RescheduleTimeLimitDialog = () => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={true} // parseInt(userReschedulableNotice) > parseInt(minutes_remaining)}
      onClose={() => console.log("Dialog cannot be close.")}
    >
      <DialogContent>
        {t("individual.booking.schedule.alert.booking_limit")}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => (window.location.href = "https://folyeo.com")}
          sx={{
            borderRadius: "12px",
            fontSize: "14px",
            minWidth: "100px",
            height: "44px",
            ...TreffasStyle.buttonHover,
          }}
        >
          {t("individual.booking.schedule.choose_schedule.button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RescheduleTimeLimitDialog;
