import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DOMPurify from "dompurify";
import React from "react";
import { Info } from "react-feather";

const DescTooltip = ({ title, description }) => {
  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <Tooltip
            onClose={onClose}
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Box>
                <Typography
                  className="heading-6"
                  sx={{
                    color: "#FFFFFF",
                    mb: "12px",
                  }}
                >
                  {title}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#AEB5BC",
                  }}
                  className="no-margin"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(description),
                  }}
                />
              </Box>
            }
            arrow
            PopperProps={{
              disablePortal: true,
              sx: {
                [`& .${tooltipClasses.arrow}`]: {
                  color: "#212529",
                },
                [`& .${tooltipClasses.tooltip}`]: {
                  maxWidth: "407px",
                  backgroundColor: "#212529",
                  borderRadius: "8px",
                  color: "#0173FE",
                  fontSize: "14px !important",
                  lineHeight: "20px",
                  p: "24px",
                  whiteSpace: "pre-line",
                },
              },
            }}
          >
            <IconButton size={"small"} onClick={onOpen}>
              <Info size={20} />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default DescTooltip;
