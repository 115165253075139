import {
  Box,
  Typography,
  Button,
  FormHelperText,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  ButtonBase,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "src/utils/http";
import TreffasStyle from "src/utils/TreffasStyle";
import { useForm, Controller as Fc } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const Schema = Yup.object({}).required();

const SurveyForm = ({
  details,
  setDetails,
  surveyThemes,
  isMoreColor,
  moreColor,
  isColorDark,
}) => {
  const { surveyId } = useParams();

  const [randomErrors, setRandomErrors] = useState({});

  const navigate = useNavigate();

  const [isProcess, setIsProcess] = useState(false);
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
    // defaultValues: {
    //   survey_name: "",
    //   survey_description: "",
    // },
  });

  const appendForm = (formdata, question) => {
    if (question.type === "question-nps") {
      formdata.append(`answer[${question.id}][]`, question.answer ?? null);
    }
  };

  const handleSurvey = (e) => {
    e.preventDefault();
    setRandomErrors({});
    let error = [];
    const formdata = new FormData(e.target);

    for (const question of details?.questionslist) {
      if (Boolean(parseInt(question.is_required))) {
        if (parseInt(question.has_answer) !== 1) {
          error = "question-required";
          setRandomErrors((prev) => {
            return {
              ...prev,
              [question.id]: {
                hasError: true,
                message: t("survey.error.questions"),
              },
            };
          });
        } else {
          appendForm(formdata, question);
        }
      } else {
        appendForm(formdata, question);
      }
    }

    if (error.length > 0) {
      console.log("form has an error:", error);
      return;
    }

    formdata.append("survey_id", surveyId);
    formdata.append("baseUrl", window.location.origin);
    setIsProcess(true);
    http
      .post(`/api/general/survey/save-respondent-answer`, formdata)
      .then((response) => {
        if (response.data.thankyou_type === "default") {
          navigate(
            `/survey/thank-you/${response.data.id}/${response.data.slug}`
          );
        } else window.location = response.data.thankyou_link;
      })
      .catch((err) => console.log(err.message))
      .finally(() => setIsProcess(false));
  };

  const checkFieldName = (field) => {
    return field.includes("first")
      ? t("survey.take.form.label.firstname")
      : field.includes("last")
      ? t("survey.take.form.label.lastname")
      : field.includes("mobile")
      ? t("survey.take.form.label.mobile")
      : field.includes("address")
      ? t("survey.take.form.label.address")
      : field.includes("email")
      ? t("survey.take.form.label.email_address")
      : field;
  };

  const updateData = (newdata, questionId) => {
    const updated_data = details.questionslist.map((item) => {
      if (item.id === questionId) {
        return {
          ...item,
          ...newdata,
        };
      }
      return item;
    });

    setDetails((prev) => {
      return {
        ...prev,
        questionslist: updated_data,
      };
    });
  };

  const commonStyle = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "6px",
      alignSelf: "stretch",
    },
    input: {
      display: "flex",
      alignItems: "center",
      padding: "14px 16px",
      gap: "12px",
      borderRadius: "12px",
      border: `1px solid ${surveyThemes.configuration?.answer_border}`,
      width: "100%",
      "& input[type='radio']": {
        border: "2px solid white",
        boxShadow: `0 0 0 1px ${surveyThemes.configuration.radio_border}`,
        appearance: "none",
        borderRadius: "50%",
        width: "18px",
        height: "18px",
        backgroundColor: "#fff",
        transition: "all ease-in 0.2s",
      },
      "& input[type='radio']:checked": {
        boxShadow: `0 0 0 1px #FFF`,
        backgroundColor: "#FFF",
        border: `3px solid ${surveyThemes.configuration.radio_border}`,
      },

      "& input[type='checkbox']": {
        border: "1px solid white",
        boxShadow: `0 0 0 1px ${surveyThemes.configuration.radio_border}`,
        appearance: "none",
        width: "18px",
        height: "18px",
        backgroundColor: "#fff",
        borderRadius: "4px",
      },
      "& input[type='checkbox']:checked": {
        boxShadow: `0 0 0 1px ${surveyThemes.configuration.radio_border}`,
        backgroundColor: `${surveyThemes.configuration.radio_border}`,
        border: `3px solid #FFF`,
      },

      "&:hover": {
        backgroundColor: surveyThemes.configuration.hover.color,
        border: `1px solid ${surveyThemes.configuration.hover.color}`,
      },
    },

    label: {
      color: "#212529", 
      fontSize: "17px",
      fontFamily: "Lato",
    },
  };

  return (
    <Box>
      <form onSubmit={handleSurvey}>
        <Box>
          {details?.questionslist.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {details?.questionslist.map((item, key) => {
                return (
                  <Box
                    key={key}
                    sx={{
                      width: "100%",
                      py: "20px",
                      ...(isMoreColor && {
                        backgroundColor: "#FFF",
                        px: "32px",
                        borderRadius: "12px",
                      }),
                    }}
                  >
                    <Box sx={{ mb: "16px" }}>
                      <Typography
                        sx={{
                          color: "#212529",
                          fontSize: "17px", 
                          fontFamily: "Lato",
                        }}
                      >
                        {key + 1}. {item.question}{" "}
                        {Boolean(parseInt(item.is_required)) && (
                          <span style={{ color: "red" }}> * </span>
                        )}
                      </Typography>
                    </Box>

                    {/* error display here */}
                    <Box>
                      {Boolean(randomErrors?.[`${item.id}`]?.hasError) && (
                        <FormHelperText error>
                          {randomErrors?.[`${item.id}`]?.message}{" "}
                        </FormHelperText>
                      )}
                    </Box>

                    {/* question-short-answer */}
                    {item.type === "question-short-answer" && (
                      <Box>
                        <TextField
                          fullWidth
                          sx={{
                            ...TreffasStyle.formInputLarge("input"),
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px !important",
                              height: "46px !important",
                              backgroundColor:
                                surveyThemes.configuration.answer_bg_color,
                            },
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                surveyThemes.configuration.answer_border,
                            },
                          }}
                          name={`answer[${item.id}][${item.choices[0].id}][]`}
                          value={item?.answer ?? ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            updateData(
                              {
                                answer: value,
                                has_answer: value.trim() === "" ? 0 : 1,
                              },
                              item.id
                            );
                          }}
                          placeholder="Enter your answer here..."
                        />
                      </Box>
                    )}

                    {/* question-one-answer */}
                    {item.type === "question-comment-essay" && (
                      <Box>
                        <TextField
                          fullWidth
                          multiline
                          minRows={3}
                          sx={{
                            ...TreffasStyle.formInputLarge("textarea"),
                            "& .MuiOutlinedInput-root": {
                              padding: "10px",
                              paddingRight: "20px",
                              paddingLeft: "20px",
                              borderRadius: "10px !important",
                              backgroundColor:
                                surveyThemes.configuration.answer_bg_color,
                            },
                            "& fieldset.MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                surveyThemes.configuration.answer_border,
                            },
                          }}
                          name={`answer[${item.id}][${item.choices[0].id}][]`}
                          value={item?.answer ?? ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            updateData(
                              {
                                answer: value,
                                has_answer: value.trim() === "" ? 0 : 1,
                              },
                              item.id
                            );
                          }}
                          placeholder="Enter your answer here..."
                        />
                      </Box>
                    )}

                    {/* question-one-answer */}
                    {item.type === "question-one-answer" && (
                      <Box sx={commonStyle.container}>
                        {item.choices.length > 0 &&
                          item.choices.map((ch, chKey) => (
                            <Box
                              key={chKey}
                              sx={{
                                ...commonStyle.input,
                                ...(item?.answer === ch.id && {
                                  backgroundColor:
                                    surveyThemes.configuration.color,
                                }),
                              }}
                            >
                              <input
                                name={`answer[${item.id}][]`}
                                value={item?.answer ?? ""}
                                onChange={(e) => {
                                  const value = ch.id;
                                  updateData(
                                    {
                                      answer: value,
                                      has_answer: 1,
                                    },
                                    item.id
                                  );
                                }}
                                type="radio"
                                id={`flexRadioDefault1dfdsf${ch.id}`}
                              />
                              <label
                                htmlFor={`flexRadioDefault1dfdsf${ch.id}`}
                                style={{
                                  ...commonStyle.label,
                                  ...(item?.answer === ch.id && {
                                    color:
                                      surveyThemes.configuration.text.selected,
                                  }),
                                }}
                              >
                                {ch.options}
                              </label>
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* question-multiple-answer */}
                    {item.type === "question-multiple-answer" && (
                      <Box sx={commonStyle.container}>
                        {item.choices.length > 0 &&
                          item.choices.map((ch, chKey) => (
                            <Box
                              key={chKey}
                              sx={{
                                ...commonStyle.input,
                                ...(item?.answer?.includes(ch.id) && {
                                  backgroundColor:
                                    surveyThemes.configuration.color,
                                }),
                              }}
                            >
                              <input
                                name={`answer[${item.id}][]`}
                                type="checkbox"
                                id={`flexCheckDefault45345${ch.id}`}
                                value={ch.id ?? ""}
                                onChange={(e) => {
                                  const value = ch.id;
                                  let checked = e.target.checked;
                                  const current_answer = item?.answer ?? [];

                                  if (checked) {
                                    current_answer.push(value);
                                    updateData(
                                      {
                                        answer: current_answer,
                                        has_answer: 1,
                                      },
                                      item.id
                                    );
                                  } else {
                                    let new_answer = current_answer.filter(
                                      (x) => x !== value
                                    );
                                    updateData(
                                      {
                                        answer: new_answer,
                                        has_answer:
                                          new_answer.length === 0 ? 0 : 1,
                                      },
                                      item.id
                                    );
                                  }
                                }}
                              />
                              <label
                                htmlFor={`flexCheckDefault45345${ch.id}`}
                                style={{
                                  ...commonStyle.label,
                                  ...(item?.answer?.includes(ch.id) && {
                                    color:
                                      surveyThemes.configuration.text.selected,
                                  }),
                                }}
                              >
                                {ch.options}
                              </label>
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* question-true-false */}
                    {item.type === "question-true-false" && (
                      <Box sx={commonStyle.container}>
                        {item.choices.length > 0 &&
                          item.choices.map((ch, chKey) => (
                            <Box
                              key={chKey}
                              sx={{
                                ...commonStyle.input,
                                ...(item?.answer === ch.id && {
                                  backgroundColor:
                                    surveyThemes.configuration.color,
                                }),
                              }}
                            >
                              <input
                                name={`answer[${item.id}][]`}
                                // value={ch.id}
                                type="radio"
                                id={`flexRadioDefault1dfdsf7788${ch.id}`}
                                value={item?.answer ?? ""}
                                onChange={(e) => {
                                  const value = ch.id;
                                  updateData(
                                    {
                                      answer: value,
                                      has_answer: 1,
                                    },
                                    item.id
                                  );
                                }}
                              />
                              <label
                                htmlFor={`flexRadioDefault1dfdsf7788${ch.id}`}
                                style={{
                                  ...commonStyle.label,
                                  ...(item?.answer === ch.id && {
                                    color:
                                      surveyThemes.configuration.text.selected,
                                  }),
                                }}
                              >
                                {ch.options}
                              </label>
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* question-yes-no */}
                    {item.type === "question-yes-no" && (
                      <Box sx={commonStyle.container}>
                        {item.choices.length > 0 &&
                          item.choices.map((ch, chKey) => (
                            <Box
                              key={chKey}
                              sx={{
                                ...commonStyle.input,
                                ...(item?.answer === ch.id && {
                                  backgroundColor:
                                    surveyThemes.configuration.color,
                                }),
                              }}
                            >
                              <input
                                name={`answer[${item.id}][]`}
                                // value={ch.id}
                                type="radio"
                                id={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                value={item?.answer ?? ""}
                                onChange={(e) => {
                                  const value = ch.id;
                                  updateData(
                                    {
                                      answer: value,
                                      has_answer: 1,
                                    },
                                    item.id
                                  );
                                }}
                              />
                              <label
                                htmlFor={`flexRadioDefault1dfdsf7788df${ch.id}`}
                                style={{
                                  ...commonStyle.label,
                                  ...(item?.answer === ch.id && {
                                    color:
                                      surveyThemes.configuration.text.selected,
                                  }),
                                }}
                              >
                                {ch.options}
                              </label>
                            </Box>
                          ))}
                      </Box>
                    )}

                    {/* question-nps */}
                    {item.type === "question-nps" && (
                      <Box>
                        {item.choices.length > 0 && (
                          <Box
                            mt={1}
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "-1px",
                              justifyContent: "space-evenly",
                              width: "100%",
                              borderRadius: "10px",
                            }}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 10].map((ch, chKey) => {
                              return (
                                <ButtonBase
                                  key={chKey}
                                  sx={{
                                    display: "flex",
                                    width: "85px",
                                    padding: "14.5px 10px",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    border: `1px solid ${surveyThemes.configuration.nps_border}`,
                                    ...(ch === 1 && {
                                      borderTopLeftRadius: "12px",
                                      borderBottomLeftRadius: "12px",
                                    }),
                                    ...(ch === 10 && {
                                      borderBottomRightRadius: "12px",
                                      borderTopRightRadius: "12px",
                                    }),

                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    color: "#353A3F",
                                    fontFamily: "Lato",
                                    "&:hover": {
                                      backgroundColor:
                                        "var(--base-color-ocean)",
                                      borderColor: "var(--base-color-ocean)",
                                      color: "#FFF",
                                    },

                                    ...(ch === item?.answer && {
                                      backgroundColor:
                                        "var(--base-color-ocean)",
                                      borderColor: "var(--base-color-ocean)",
                                      color: "#FFF",
                                    }),
                                  }}
                                  onClick={() => {
                                    const value = ch;
                                    updateData(
                                      {
                                        answer: value,
                                        has_answer: 1,
                                      },
                                      item.id
                                    );
                                  }}
                                >
                                  {ch}
                                </ButtonBase>
                              );
                            })}
                          </Box>
                        )}

                        {item.choices.length > 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              gap: "10px",
                              mt: "8px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color: isColorDark ? "#d5d5d5" : "#6E757C",
                                  fontSize: "13px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "20px",
                                }}
                              >
                                {" "}
                                {item.choices?.[0]?.options ?? ""}{" "}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography
                                sx={{
                                  color: isColorDark ? "#d5d5d5" : "#6E757C",
                                  fontSize: "13px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "20px",
                                }}
                              >
                                {" "}
                                {item.choices?.[1]?.options ?? ""}{" "}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                );
              })}

              {/* enable optins */}
              {Boolean(parseInt(details?.is_optin)) && (
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Typography sx={{ ...commonStyle.label }}>
                      {t("survey.take.form.personal_information")}
                    </Typography>
                  </Box>

                  <Box mt={1}>
                    <Grid container spacing={3}>
                      {details?.optins.split(",").map((xfield, fieldKey) => {
                        let fieldname = checkFieldName(xfield);
                        return (
                          <Grid item xs={12} md={12} key={fieldKey}>
                            <FormControl fullWidth variant="standard">
                              <Typography
                                sx={{
                                  ...TreffasStyle.formLabel,
                                  textTransform: "capitalize",
                                  color: isColorDark ? "#d5d5d5" : "#6E757C",
                                }}
                              >
                                {fieldname}{" "}
                                <span style={{ color: "red" }}> * </span>
                              </Typography>
                              <Fc
                                name={xfield}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    value={field.value ?? ""}
                                    fullWidth
                                    placeholder={`Enter ${fieldname}`}
                                    type={xfield === "email" ? "email" : "text"}
                                    sx={{
                                      ...TreffasStyle.formInputLarge("input"),
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "10px !important",
                                        height: "46px !important",
                                        backgroundColor:
                                          surveyThemes.configuration
                                            .answer_bg_color,
                                      },
                                      "& fieldset.MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor:
                                            surveyThemes.configuration
                                              .answer_border,
                                        },
                                    }}
                                    error={Boolean(errors.name)}
                                    helperText={errors.name?.message}
                                    required
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              )}

              {/* optins options */}

              <Box
                sx={{
                  marginTop: "50px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        fontSize: "14px",
                        lineHeight: "31px",
                        minHeight: "44px",
                        borderRadius: "8px",
                        ...TreffasStyle.buttonHover,
                        border: `1px solid ${
                          isMoreColor
                            ? surveyThemes.configuration.border
                            : surveyThemes.configuration.radio_border
                        }`,
                        color: isMoreColor
                          ? "#FFF"
                          : surveyThemes.configuration.color,
                        "& :hover": {
                          color: "#FFF",
                        },
                      }}
                      onClick={() => {
                        window.location.href = "https://folyeo.com";
                      }}
                    >
                      {t("survey.take.form.button.cancel")}
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      variant="contained"
                      color={"primary"}
                      sx={{
                        fontSize: "14px",
                        lineHeight: "31px",
                        minWidth: "120px",
                        minHeight: "44px",
                        borderRadius: "8px",
                        backgroundColor: isMoreColor
                          ? "#000"
                          : surveyThemes.configuration.color,
                        ...TreffasStyle.buttonHover,
                      }}
                      startIcon={
                        isProcess && (
                          <CircularProgress color="inherit" size={15} />
                        )
                      }
                      disabled={isProcess}
                      type="submit"
                    >
                      {t("survey.take.form.button.submit")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography sx={{ color: "#838383" }}>
              {t("survey.take.form.error.noquestion_found")}
            </Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default SurveyForm;
